import React from "react";
import { Modal } from "react-bootstrap";

export default function VerifyDialog(props) {
  const { children, onClose, show, size, title, bodyProps, className, hideClose, centered, unhideHeader } = props;

  return (
    <Modal
      aria-hidden={true}
      centered
      size={size ? size : "lg"}
      backdrop="static"
      keyboard={true}
      show={show}
      onHide={onClose}
      className="verification-modal"
    >
      {unhideHeader && (
        <Modal.Header className="verify-header" closeButton={!hideClose}>
         <Modal.Title className="verify-title h6">{title}</Modal.Title>
       </Modal.Header>
      )}
     
      <Modal.Body className="verify-body" {...bodyProps}>{children}</Modal.Body>

    </Modal>
  );
}